function Habilidades() {
    return(
        <section className="flex-row-center habilidades w100">
            <div className="text-habilidades">
                <h2>Habilidades</h2>
            </div>
            <div className="conteiner flex-row-center w90">
                <div className="item-habilidade w50">
                    <label htmlFor="">Html & CSS</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "80%"}}>80%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">JavaScript</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "50%"}}>50%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">PHP</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "50%"}}>50%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">MySql / SQL</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "60%"}}>60%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">WordPress</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "70%"}}>70%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">APIs & WebHooks</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "35%"}}>35%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">C#</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "35%"}}>35%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">Photoshop</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "40%"}}>40%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">SEO</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "50%"}}>50%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">UX / UI</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "50%"}}>50%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">Linux</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "30%"}}>30%</div>
                    </div>
                </div>
                <div className="item-habilidade w50">
                    <label htmlFor="">React JS</label>
                    <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                        <div className="progress-bar" style={{width: "20%"}}>20%</div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Habilidades;