import './Header.css';
function Header() {
    return (
        <div className="Header intro conteiner">
            <img src="img/perfil.jpg" className="shadow-box" alt="Profile Picture"/>
            <h1>Gabriel Oliveira Sousa</h1>
            <ul className="social-networks">
                <li><a href="https://www.linkedin.com/in/gabrielo582" alt=".." rel="noopener" target="_blank">LinkedIn</a></li>
                <li><a href="https://github.com/gabrielo582" alt=".." rel="noopener" target="_blank">GitHub</a></li>
                <li><a href="https://instagram.com/gabrielo582" alt=".." rel="noopener" target="_blank">Instagram</a></li>
            </ul>

            <button className="btn btn-primary m15" id="btnOpenForm" onClick={window.openCalendly}>Agendar uma reunião</button>
            <br/>
            <p className="p20">
                Entusiasta do digital comecei a atuar a me profissionalizar 2019, onde iniciei meus estudos em Tecnologia e Marketing, tive diversas experiencias começando por marketing atuei como design, copywrite, otimização de sites(SEO). Em Tecnologia pude desenvolver plataformas web utilizando no-code, e websites e loja com Wordpress e Woocommerce além de HTML5, CSS3 e JS. Também atuei por dois anos em administração onde desenvolvi habilidades focadas em desenvolvimento de negócios. Atualmente curso TI no Senac e estudo empreendedorismo e inovação em meu tempo livre.
            </p>
        </div>
    )
}

export default Header;