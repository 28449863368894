import Projetos from './Projetos';
import Academico from './Academico';
import Profissional from './Profissional';
function Tabs() {
    function alternarTab(tabId) {
        let tabs = document.querySelectorAll('.tab');
        let tabVisible = document.getElementById(tabId);
        tabs.forEach(tab => {
            if(!tab.classList.contains('invisible')){
                tab.classList.add('invisible');
            }
        });
        tabVisible.classList.remove('invisible');
    }
    return(
        <section className="tabs conteiner">
            <nav>
                <ul className="flex-row-center">
                    <li onClick={() => alternarTab('projetct')}>Meus Projetos</li>
                    <li onClick={() => alternarTab('academic')}>Historico Academico</li>
                    <li onClick={() => alternarTab('professional')}>Experiencias Profissionais</li>
                </ul>
            </nav>
            <div className="tab-content h50 shadow-box">
                <Projetos></Projetos>
                <Academico></Academico>
                <Profissional></Profissional>
            </div>
        </section>
    );
}
export default Tabs;