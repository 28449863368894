import ProjetoWeb from "./ProjetoWeb";
import ProjetoSocialMidia from "./ProjetoSocialMidia";
function Projetos() {
    return (
        <div id="projetct" className="tab">
            <div className="flex-column-center">
                <h2>Meus Projetos</h2>
                <div className="projectos">

                    <h3 className="text-center">Web</h3>
                    <div>
                        <div className="webProject">
                            <ProjetoWeb 
                                img="./img/img-meugestor.png" 
                                titulo="Website e sistema de para controle financeiro pessoal"
                                descricao="<b>Toda a praticidade que a planilha não te oferece</b>. Organize suas finanças pessoais e familiar, tenha controle dos seus gastos e dividas"
                                link="https://gabrielo.com.br/meugestor"
                            />
                            <ProjetoWeb 
                                img="./img/img-hdpromo.png" 
                                titulo="Website HDPromo - grafica"
                                descricao="A HD promo atua com ambientação comercial, comunicação visual e trade
                                marketing, oferecendo
                                serviço rápido, impressão de qualidade, com excelente acabamento e
                                instalação. Acesse"
                                link="https://hdpromo.com.br/homologa"
                            />
                            <ProjetoWeb 
                                img="./img/img-turiya.png" 
                                titulo="Website Turiya Renováveis - Energia"
                                descricao="A Turiya é a primeira empresa a ofertar o modelo de geração solar distribuída para clientes PJs com o apoio dos nossos parceiros."
                                link="https://gabrielo.com.br/meugestor"
                            />
                            <ProjetoWeb 
                                img="./img/img-casaton.png" 
                                titulo="Verificador de disponibilida Casaton - Hotel"
                                descricao="Aqui você vai experimentar as melhores sensações do convívio com a biodiversidade da Mata Atlântica ao redor de montanhas, matas, cachoeiras, praias e o mar."
                                link="https://www.casaton.eco.br/reserva"
                            />
                        </div>
                    </div>

                    <h3 className="text-center">Social Midia</h3>
                    <div className="flex-column-center">
                        <ProjetoSocialMidia
                            setor="Mobiliaria"
                            img={["./img/img-bauhaus-post-01.jpg","./img/img-bauhaus-post-02.jpg","./img/img-bauhaus-post-03.jpg"]}
                            perfil="@bauhausmarcenaria"
                        />
                        <ProjetoSocialMidia
                            setor="Imobiliaria"
                            img={["./img/img-dequeiroz-post-01.jpg","./img/img-dequeiroz-post-02.jpg","./img/img-dequeiroz-post-03.jpg"]}
                            perfil="@dequeirozimoveis"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projetos;