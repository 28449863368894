function ListarImagens(info) {
    return (
        <>
            {
                (info.img).map(img => {
                    return (
                        <div key={img} className="col flex-row-start">
                            <img className="w100 shadow-box img-zoom-hover" src={img} alt="..." />
                        </div>
                    )
                })
            }
        </>
    )
}
export default ListarImagens;