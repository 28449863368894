import Head from './componentes/Head';
import Header from './componentes/Header';
import Habilidades from './componentes/Habilidades';
import Tabs from './componentes/Tabs';

function App() {
  return (
    <div className="App">
      <Head/>
      <Header/>
      <Habilidades/>
      <Tabs/>
    </div>
  );
}

export default App;
