function ProjetoWeb(info) {
    return(
        <div className="card mb-3">
            <img src={info.img} className="card-img-top" alt="..."/>
            <div className="card-body">
                <h5 className="card-title" dangerouslySetInnerHTML = {{__html: info.titulo}}></h5>
                <p className="card-text" dangerouslySetInnerHTML = {{__html: info.descricao}}></p>
                <a href={info.link} target="_blank" className="btn btn-primary">Ver na Web</a>
            </div>
        </div>
    )
}
export default ProjetoWeb;