import ListarImagens from './ListarImagens';

function ProjetoSocialMidia(info) {
    return(
        <div className="card text-center">
            <div className="card-header">
                {info.setor}
            </div>
            <div className="card-body">
                <div className="container text-center">
                    <div className="row align-items-start">
                        <ListarImagens img={info.img}></ListarImagens>
                    </div>
                </div>
            </div>
            <div className="card-footer text-muted">
                {info.perfil}
            </div>
        </div>
    )
}
export default ProjetoSocialMidia;