function Profissional() {
    return (
        <div id="professional" className="invisible tab">
            <div className="flex-column-center">
                <h2>Experiencias Profissionais</h2>
                <div className="flex-column-center w100">
                    <div className="card w100">
                        <div className="card-header">
                            Flexdoc
                        </div>
                        <div className="card-body">
                            <blockquote className="blockquote mb-0">
                                <p>Analista de Marketing Pleno</p>
                                <p><b>Atividades</b></p>
                                <ul>
                                    <li>Escrita e Revisão de Mateias para Blogs e Redes Sociais</li>
                                    <li>Criação de Dashboard para Análise de dados (BI)</li>
                                    <li>Analise de Campanhas(Google Ads)</li>
                                    <li>Pesquisas de Mercado/ Analise de Concorrência</li>
                                    <li>Design gráfico - Criação de Artes para uso interno</li>
                                    <li>Campanhas de E-mail Marketing</li>
                                    <li>Filtragem de Leads</li>
                                    <li>Elaboração e Analise de Pesquisas Internas</li>
                                    <li>Elaboração de Planilhas para analise de produtividade</li>
                                </ul>
                                <footer className="blockquote-footer">Janeiro 2021 - <cite
                                        title="Source Title">Atual</cite></footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card w100">
                        <div className="card-header">
                            A Liga Digital
                        </div>
                        <div className="card-body">
                            <blockquote className="blockquote mb-0">
                                <p>Assistente de E-commerce</p>
                                <p><b>Atividades</b></p>
                                <ul>
                                    <li>Cadastro de Produtos para E-commerce</li>
                                    <li>Desenvolvimento de Websites com WordPress</li>
                                    <li>Criação de Laminas de Produtos com HTML5 e CSS3</li>
                                    <li>Design Gráfico para redes sociais e lojas virtuais</li>
                                    <li>Suporte a Eventos Online</li>
                                </ul>
                                <footer className="blockquote-footer">Outubro 2020 - <cite title="Source Title">Janeiro
                                        2021</cite></footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card w100">
                        <div className="card-header">
                            Desenvolvedor Web
                        </div>
                        <div className="card-body">
                            <blockquote className="blockquote mb-0">
                                <p>Analista de Marketing Pleno</p>
                                <p><b>Atividades</b></p>
                                <ul>
                                    <li>Atividades Internas</li>
                                    <ul>
                                        <li>Prospecção Ativa</li>
                                        <li>Realização de Fluxo de Caixa</li>
                                        <li>Analise de Campanhas(Google Ads)</li>
                                        <li>Gestão de Contratos Marketing Interno</li>
                                        <li>Atendimento ao Cliente</li>
                                    </ul>
                                    <li>Atividades Externas</li>
                                    <ul>
                                        <li>Desenvolvimento Web</li>
                                        <li>Elaboração de Protótipos</li>
                                        <li>Suporte aos clientes</li>
                                        <li>Desenvolvimento de Loja Virtuais</li>
                                        <li>Web sites empresariais</li>
                                        <li>Site Portfolios, Blog</li>
                                        <li>Páginas de agendamento</li>
                                        <li>Design Gráfico</li>
                                        <li>Criação de Logomarcas, Flyer, Banners e Cartões de Visitas</li>
                                    </ul>
                                </ul>
                                <footer className="blockquote-footer">Junho 2020 - <cite title="Source Title">Julho
                                        2021</cite></footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profissional;