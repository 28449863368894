function Academico() {
    return (
        <div id="academic" className="invisible tab">
            <div className="flex-column-center">
                <h2>Historico Academico</h2>
                <div className="flex-column-center w100">
                    <div className="card w100">
                        <div className="card-header">
                            Centro Universitário Senac
                        </div>
                        <div className="card-body">
                            <blockquote className="blockquote mb-0">
                                <p>Sistemas para Internet: <b>Tecnologia da Informação</b></p>
                                <footer className="blockquote-footer">Fevereiro 2023 - <cite title="Source Title">Junho
                                        2026</cite></footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card w100">
                        <div className="card-header">
                            Senac Brasil
                        </div>
                        <div className="card-body">
                            <blockquote className="blockquote mb-0">
                                <p>Rede de Computadores: <b>Ensino Técnico</b></p>
                                <footer className="blockquote-footer">Outubro de 2021 <cite title="Source Title">Março
                                        de 2022</cite></footer>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card w100">
                        <div className="card-header">
                            Senac Brasil
                        </div>
                        <div className="card-body">
                            <blockquote className="blockquote mb-0">
                                <p>Montagem e Manutenção de Computadores: <b>Ensino Técnico</b></p>
                                <footer className="blockquote-footer">Outubro de 2021 <cite title="Source Title">Março
                                        de 2022</cite></footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Academico;